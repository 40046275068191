<template>
  <div class="fourth-part" id="brands_part">
    <div class="fourth-part-title">
      Which brands have succeeded with indoor ads?
    </div>
    <div class="fourth-part-info">
      Most brands, particularly those with international reach, are leveraging indoor advertising for brand promotion!
    </div>
    <div class="fourth-part-content">
      <div class="logo-4-item">
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg1.png')"/>
        </div>
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg2.png')"/>
        </div>
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg3.png')"/>
        </div>
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg4.png')"/>
        </div>
      </div>
      <div class="logo-3-item">
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg5.png')"/>
        </div>
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg6.png')"/>
        </div>
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg7.png')"/>
        </div>
      </div>
      <div class="logo-4-item">
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg8.png')"/>
        </div>
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg9.png')"/>
        </div>
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg10.png')"/>
        </div>
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg11.png')"/>
        </div>
      </div>
      <div class="logo-3-item">
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg12.png')"/>
        </div>
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg13.png')"/>
        </div>
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg14.png')"/>
        </div>
      </div>
      <div class="logo-3-item">
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg15.png')"/>
        </div>
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg16.png')"/>
        </div>
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg17.png')"/>
        </div>
      </div>
      <div class="logo-4-item">
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg18.png')"/>
        </div>
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg19.png')"/>
        </div>
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg20.png')"/>
        </div>
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg21.png')"/>
        </div>
      </div>
      <div class="logo-3-item">
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg22.png')"/>
        </div>
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg23.png')"/>
        </div>
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg24.png')"/>
        </div>
      </div>
      <div class="logo-4-item">
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg25.png')"/>
        </div>
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg26.png')"/>
        </div>
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg27.png')"/>
        </div>
        <div  class="logo-item" >
          <img :src="require('@/assets/adslogoimg28.png')"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.fourth-part {
  margin-top: 120px;
  padding: 0 120px;
}
.fourth-part-title {
  font-family: Alibaba PuHuiTi 2.0;
  font-size: 48px;
  font-weight: 700;
  line-height: 64px;
  text-align: center;
  color: #333;
}
.fourth-part-info {
  font-family: Alibaba PuHuiTi 2.0;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  color: #333;
  margin: 10px 0 60px 0;
}
.fourth-part-content { 
  /* border: 1px solid black; */
  /* width: 100%; */
  height: 520px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.logo-4-item, .logo-3-item{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.logo-4-item {
  height: 520px;
}
.logo-3-item {
  height: 380px;
}
.logo-item {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #fff; */
  width: 100px;
  height: 100px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px 4px #116AE21A;
}
.logo-item img {
  width: 64px;
}
@media screen and (max-width: 830px) {
  .fourth-part {
    padding: 40px 20px;
    margin: 0;
  }
  .fourth-part-title {
    padding: 0 20px;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
  }
  .fourth-part .fourth-part-info {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .fourth-part-content, .logo-4-item, .logo-3-item {
    height: inherit;
  }
  .logo-4-item {
    justify-content: space-around;
    height: 150px;
  }
  .logo-3-item {
    height: 112px;
  }
  .logo-3-item {
    justify-content: center;
  }
  .fourth-part .logo-item {
    width: 28px;
    height: 28px;
  }
  .fourth-part .logo-item img {
    width: 20px;
  }
}
</style>