<template>
  <div class="footer-component">
    <div class="top">
      <div class="logo" @click="goTo()">
        <img :src="require('@/assets/logowhite.png')" alt="">
      </div>
      <div class="menu-box">
        <div class="menu-item" @click="anchorTo('about_us_part')">About Us</div>
        <div class="menu-item" @click="anchorTo('ads_part')">Indoor Ads</div>
        <div class="menu-item" @click="anchorTo('cases_part')">Cases</div>
        <div class="menu-item" @click="anchorTo('brands_part')">Brands</div>
      </div>
    </div>
    <div class="main">
      <div class="contact">
        <div class="first-contact">
          <div>+966 58 260 0828</div>
          <div>info@swidavision.com</div>
        </div>
        <div class="second-contact">
          <div class="icon-box">
            <div class="icon-item icon-item1">
              <!-- <img :src="require('@/assets/icon-footer-1.png')" alt=""> -->
            </div>
            <div class="icon-item icon-item2">
              <!-- <img :src="require('@/assets/icon-footer-2.png')" alt="">          -->
            </div>
            <div class="icon-item icon-item3">
              <!-- <img :src="require('@/assets/icon-footer-3.png')" alt="">          -->
            </div>
            <div class="icon-item icon-item4">
              <!-- <img :src="require('@/assets/icon-footer-4.png')" alt="">          -->
            </div>
            <div class="icon-item icon-item5">
              <!-- <img :src="require('@/assets/icon-footer-5.png')" alt="">          -->
            </div>
            <div class="icon-item icon-item6">
              <!-- <img :src="require('@/assets/icon-footer-6.png')" alt="">          -->
            </div>
            <div class="icon-item icon-item7">
              <!-- <img :src="require('@/assets/icon-footer-7.png')" alt="">          -->
            </div>
          </div>
        </div>
      </div>
      <div class="active-box">
        <div class="active-bottons">
          <el-button @click="goTo('contact', {type:1})">Property Owner</el-button>
          <el-button @click="goTo('contact', {type:2})" type="info" style="background: transparent">Advertising Client</el-button>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div>
        Jeddah, Saudi Arabia
      </div>
      <div>
        © SwidaVision Advertising Co. 2024 All Rights Reserved
      </div>
    </div>
  </div>
</template>
<script setup>

import {useRouter, useRoute} from 'vue-router'
const router = useRouter();
const route = useRoute();

const anchorTo = (anchorName) => {
  console.log(route,anchorName);
  console.log(route.fullPath)
  let fullPath = route.fullPath
  if (fullPath === '/' || fullPath === '/home') {
    window.scrollTo({
      top: document.getElementById(anchorName)?.offsetTop,
      behavior: 'smooth'
    })
  } else {
    router.push('/');
    setTimeout(()=>{
      window.scrollTo({
        top: document.getElementById(anchorName)?.offsetTop,
        behavior: 'smooth'
      })
    }, 700)
  }
}

const goTo = (path, params) => {
  if (path) {
    router.push({name: path, query:params});
    // switchMenu()
  } else {
    router.push('/')
  }
  console.log(path, router,route);
}
</script>
<style scoped>
  .footer-component {
      /* height: 100px; */
      /* background: #888080; */
      /* background-image: url('../assets/footerbgimg.png'); */
      /* background: linear-gradient(to bottom, rgba(4, 2, 39, 0.6), rgb(16,2,66) ); */
      /* opacity: 0.2; */
      padding: 60px 120px;
      height: 440px;
      background: linear-gradient(180deg, #51648A 0%, #151A24 100%);

  }
  .footer-component .logo {
    cursor: pointer;
  }
  .footer-component .logo img{
    height: 64px;
  }
  .top {
    display: flex;
    justify-content: space-between;
  }
  .menu-box {
    display: flex;
  }
  .menu-item {
    cursor: pointer;
    margin-left: 32px;
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color:#fff
  }
  .main {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
  }
  .first-contact {
    width: 200px;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color:#fff;
  }
  .second-contact {
    width: 50%;
    padding-top: 100px;
    padding-bottom: 60px;
  }
  .icon-box {
    display: flex;
    justify-content: space-around;
    /* width: 100%; */
  }
  .footer-component .second-contact .icon-item {
    /* width: 24px;
    height: 24px; */
    margin-right: 10px;
    cursor: pointer;
  }
  .footer-component .second-contact .icon-item img {
    width: 24px;
    height: 24px;
  }
  .footer-component .second-contact .icon-item {
    width: 58px;
    height: 24px;
  }
  .footer-component .second-contact .icon-item1 {
    background-image: url('../assets/icon-footer-11.png');
    background-size: cover;
  }
  .footer-component .second-contact .icon-item2 {
    background-image: url('../assets/icon-footer-22.png');
    background-size: cover;
  }
  .footer-component .second-contact .icon-item3 {
    background-image: url('../assets/icon-footer-33.png');
    background-size: cover;
  }
  .footer-component .second-contact .icon-item4 {
    background-image: url('../assets/icon-footer-44.png');
    background-size: cover;
  }
  .footer-component .second-contact .icon-item5 {
    background-image: url('../assets/icon-footer-55.png');
    background-size: cover;
  }
  .footer-component .second-contact .icon-item6 {
    background-image: url('../assets/icon-footer-66.png');
    background-size: cover;
  }
  .footer-component .second-contact .icon-item7 {
    background-image: url('../assets/icon-footer-77.png');
    background-size: cover;
  }

  .footer-component .second-contact .icon-item1:hover {
    background-image: url('../assets/icon-footer-1.png');
    background-size: cover;
  }
  .footer-component .second-contact .icon-item2:hover {
    background-image: url('../assets/icon-footer-2.png');
    background-size: cover;
  }
  .footer-component .second-contact .icon-item3:hover {
    background-image: url('../assets/icon-footer-3.png');
    background-size: cover;
  }
  .footer-component .second-contact .icon-item4:hover {
    background-image: url('../assets/icon-footer-4.png');
    background-size: cover;
  }
  .footer-component .second-contact .icon-item5:hover {
    background-image: url('../assets/icon-footer-5.png');
    background-size: cover;
  }
  .footer-component .second-contact .icon-item6:hover {
    background-image: url('../assets/icon-footer-6.png');
    background-size: cover;
  }
  .footer-component .second-contact .icon-item7:hover {
    background-image: url('../assets/icon-footer-7.png');
    background-size: cover;
  }

  .active-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 60px;
  }
  .active-box .message {
    margin-bottom: 30px;
  }
  .bottom {
    border-top: 1px solid rgb(255,255,255, 0.1);
    display: flex;
    justify-content: center;
    padding-top: 40px;
  }
  .bottom div {
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
    color:rgb(255,255,255);
  }
@media screen and (max-width: 830px) {
  .footer-component {
    height: 50vh;
    padding: 5vw 10vw;
  }
  .footer-component .logo {
    cursor: pointer;
  }
  .menu-box {
    display: none;
  }
  .active-box {
    display: none;
  }
  .menu-item {

  }
  .bottom {
    padding-top: 1vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
