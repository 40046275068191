<template>
  <div class="third-part" id="cases_part">
    <div class="third-part-title">Case Gallery</div>
    <div class="content"  @click="goToPage">
      <div class="info">
        <div class="info-text">
          Indoor ads work for almost any industry
        </div>
        <div class="info-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="M754.752 480H160a32 32 0 1 0 0 64h594.752L521.344 777.344a32 32 0 0 0 45.312 45.312l288-288a32 32 0 0 0 0-45.312l-288-288a32 32 0 1 0-45.312 45.312z"></path></svg>
          </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from 'vue-router'
const currentRoute = useRoute();
const currentRouter = useRouter();
console.log(currentRoute,currentRouter);
const goToPage = () => {
  currentRouter.push('/display');
}
</script>
<style>
.third-part {
  /* height: 640px; */
  padding: 0 120px;
  max-width: 1200px;
  margin: 0 auto;
}
.third-part-title {
  font-family: Alibaba PuHuiTi 2.0;
  font-size: 48px;
  font-weight: 700;
  line-height: 64px;
  text-align: center;
  padding-bottom: 64px;
  color: #000;
}
.content {
  border-radius: 8px;
  height: 600px;
  background-image: url('../../assets/thirdbgimg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: end;
  cursor:pointer;
}
.info {
  padding: 0 32px;
  text-align: left;
  width: 100%;
  height: 120px;
  background-color: rgba(7, 88, 249, 0.417);
  color: #fff;
  font-size: 32px;
  line-height: 120px;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.info-icon {
  width: 20px;
  height: 20px;
  color: #fff;
}

@media screen and (max-width: 830px) {
  .third-part {
    padding: 0 20px;
  }
  .third-part .third-part-title {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    padding: 0;
    margin-bottom: 20px;
  }
  .third-part .content {
    height: 180px;
  }
  .third-part .info {
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    line-height: 40px;
    align-items: center;
  }
}

</style>