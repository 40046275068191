import {createRouter,createWebHashHistory} from 'vue-router'
import Home from './views/home/index.vue';
import Contact from './views/contact/index.vue';
import Display from './views/display/index.vue';

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/',
            component: Home
        },
        {
            path: '/home',
            name: 'home',
            component: Home
        },
        {
            path: '/contact',
            name: 'contact',
            component: Contact
        },
        {
            path: '/display',
            name: 'display',
            component: Display
        }
    ]
})
router.afterEach(() => {
    window.scrollTo(0,0)
})
export default router;