<template>
  <div class="information-part" id="about_us_part">
    <div class="picture">
      <div class="picture-left">
        <img :src="require('@/assets/aboutus1.png')" width="240px" height="320px" alt="">
      </div>
      <div class="picture-right">
        <img :src="require('@/assets/aboutus2.png')" width="320px" alt="">
        <img :src="require('@/assets/aboutus3.png')" width="180px" style="margin-top: 20px;" alt="">
      </div>
    </div>
    <div class="text-area">
      <div class="text-title">
        About Us
      </div>
      <div class="text-content">
        <div class="text-item">
          <!-- <div class="text-item-title">Title One</div> -->
          <div class="text-item-info">
            SwidaVision Media focus on indoor advertising, offering a unique and sustained reach to your target audience. 
          </div>
        </div>
        <div class="text-item">
          <!-- <div class="text-item-title">Title Two</div> -->
          <div class="text-item-info">
            Our goal is to enhance the power of your brand in an efficient and intelligent way.
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
export default {

}
</script>

<style>
.information-part {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 560px;
  padding: 40px;
  /* height: 640px; */
  max-width: 1200px;
  margin: 0 auto;
}
.picture {
  /* height: 100%; */
  /* background: #ddd; */
  padding: 40px 0;
  display: flex;
}
.picture-left {
  padding-top: 100px;
}
.picture-right {
  padding-left: 20px;
  float: left;
  display: flex;
  flex-direction: column;
}
.text-area {
  /* height: calc(100% - 40px); */
  /* background: #fff; */
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.text-title {
  font-size: 48px;
  color: #000000;
  font-weight: 600;
  text-align: left;
  margin-bottom: 20px;
}
.text-content {
  color:#333;
}
.text-item {
  text-align: left;
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 28px;
  color: #000000;
}
.text-item .text-item-title {
  font-size: 26px;
  font-weight: 400;
  /* margin-bottom: 14px; */
}

@media screen and (max-width: 830px) {
  .information-part {
    display: block;
  }
  .picture {
    display: none;
  }
  .text-area {
    padding: 0;
  }
  
  .text-title {
    font-family: Inter;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color:#000;
  }
  .text-item {
    margin: 0;
  }
  .text-item-info {
    font-family: Inter;
    color:#000;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

  }
}

</style>