<template>
  <div class="display-page">
    <div class="title-box">
      <div class="title-text">
        Case Gallery
      </div>
    </div>
    <div class="main-box">
      <div class="content-item-box">
        <!-- <div class="image-item-box-title">
          Fast food brand cases
        </div> -->
        <div class="image-item-box">
          <div class="image-item" v-for="(item, index) in srcList" :key="index">
            <el-image
              class="my-el-image"
              :src="item"
              :zoom-rate="1.2"
              :max-scale="7"
              :min-scale="0.2"
              :preview-src-list="srcList"
              :initial-index="index"
              fit="cover"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// import axios from 'axios'
import {ref} from 'vue'
  const srcList = ref([
    require('@/assets/image-case-1.png'),
    require('@/assets/image-case-2.png'),
    require('@/assets/image-case-3.png'),
    require('@/assets/image-case-4.png'),
    require('@/assets/image-case-5.png'),
    require('@/assets/image-case-6.png'),
    require('@/assets/image-case-7.png'),
    require('@/assets/image-case-8.png'),
    require('@/assets/image-case-9.png'),
    require('@/assets/image-case-10.png'),
    require('@/assets/image-case-11.png'),
    require('@/assets/image-case-12.png'),
    require('@/assets/image-case-13.png'),
    require('@/assets/image-case-14.png'),
    require('@/assets/image-case-15.png'),
    require('@/assets/image-case-16.png'),
  ])

  // axios.get('api/cases/').then(res=> {
  //   console.log('cases', res);
  //   let resData = res.data.data || []
  //   if (resData.length > 0) {
  //     let formDataList = resData.map(item => {
  //       return item.cover_image
  //     })
  //     console.log(formDataList)
  //     srcList.value = formDataList || []
  //   }
  // }).catch(err => {
  //   console.log('err', err)
  // })
  // const showImg = ref(require('@/assets/3.jpeg'));
  // function clickImage(imgUrl) {
  //   alert(imgUrl);
  //   showImg.value = imgUrl
  // }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .display-page {
    }
    .display-page .title-box {
      /* background: #7a7474; */
      /* padding: 60px 0; */
      height: 640px;
      background: url('../../assets/casegalleerybgimg.png');
      background-repeat: no-repeat;
      background-size: 100% 100% ;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .title-text {
      font-family: DIN;
      font-size: 140px;
      font-weight: 700;
      line-height: 170.94px;
      text-align: center;
      color: #fff;
    }
    .display-page .title-box .title-info {
      font-size: 18px;
      color: #fff;
    }
    .main-box {
      margin-top: 120px;
    }
    .content-item-box {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .display-page .image-item-box-title {
      padding: 30px 0;
      font-size: 26px;
      font-weight: bold;
      color: rgb(54, 173, 220);
    }
    .display-page .image-item-box {
      display: grid;
      grid-template-columns: repeat(4, 270px);
      grid-auto-rows: 400px;
      grid-gap: 20px;
      /* background: black; */
    }
    .display-page .image-item {
      width: 270px;
      height: 360px;
      box-shadow: 0px 4px 8px 4px #116AE21A;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .display-page .image-item .my-el-image {
      width: 234px;
      height: 310px;
    }
@media screen and (max-width: 830px) {
  .display-page .title-box {
    height: 21vh;
    background-size: 100%;
  }
  /* .display-page .image-item-box {
    display: grid;
    grid-template-columns: repeat(auto, 170px);
    grid-auto-rows: 230px;
    grid-gap: 10px;
  } */
  /* .display-page .image-item {
    width: 175px;
    height: 230px;
  } */
  .title-text {
    font-size: 5vh;
    line-height: 5vh;
  }
  .main-box {
    margin-top: 5vh;
  }
  .display-page .image-item-box {
    display: grid;
    grid-template-columns: repeat(2, 180px);
    grid-auto-rows: 250px;
    grid-gap: 1.5vw;
  }
  .display-page .image-item {

    width: 175px;
    height: 230px;
  }
  .display-page .image-item .my-el-image{
    width: 150px;
    height: 200px;
  }
}
</style>
