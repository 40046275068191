<template>
  <div class="index-page">
    <HeaderComponent></HeaderComponent>
    <!-- <DisplayPage /> -->
    <!-- <ContactUs /> -->
    <!-- <HomePage /> -->
    <RouterView></RouterView>
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from '../components/HeaderComponents.vue';
import FooterComponent from '../components/FooterComponents.vue';
// import HomePage from './home/index.vue'
// import DisplayPage from './display/index.vue'
// import ContactUs from './contact/index.vue'
export default {
  name: 'HelloWorld',
  components: {
    HeaderComponent,
    FooterComponent,
    // HomePage,
    // DisplayPage,
    // ContactUs
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .index-page {
    padding-top: 88px;
    /* height: 100%; */
    background-image: url('../assets/bgimg4x.png');
    background-size: 100% auto;
    /* background */
  }
  @media screen and (max-width: 830px) {
    .index-page {
      padding: 0;
    }
  }
</style>
