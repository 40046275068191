<template>
  <div class="home-page">
    <FirstPart />
    <InformationPage />
    <SecondPart />
    <ThirdPart />
    <FourthPart />
  </div>
</template>

<script>
import FirstPart from './firstPart.vue'
import SecondPart from './secondPart.vue'
import ThirdPart from './thirdPart.vue'
import FourthPart from './fourthPart.vue'
import InformationPage from './informationPart.vue'
export default {
  name: 'HomePage',
  props: {
    msg: String
  },
  components: {
    InformationPage,
    FirstPart,
    SecondPart,
    ThirdPart,
    FourthPart
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .home-page {
        /* height: calc(100vh - 100px); */
        /* height: calc(100% - 172px); */
        /* background: #eee; */
      margin-bottom: 120px;
    }
    @media screen and (max-width: 830px) {
      .home-page {
        margin-bottom: 40px;
      }
    }
</style>
