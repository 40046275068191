<template>
  <div class="contact-us-page">
    <!-- <div class="updateing-box"> -->
    <div :class="{'updateing-box': true, 'updateing-box-bg-client': contactType == 2}">
      <div class="top-text">
        Advertising coverage is continuously updating
      </div>
      <div class="time-box">
        <img :src="require('@/assets/icon-clock.png')" alt="">
        <div class="time">{{nowDateStr}}</div>
      </div>
      <div class="bottom-data">
        <div class="data-item">
          <div class="item-num">
            <span>9200</span>
            <img :src="require('@/assets/icon-plus2x.png')" alt="">
          </div>
          <div class="item-info">Building Coverage</div>
        </div>
        <div class="data-item">
          <div class="item-num">
            <span>691000</span>
            <img :src="require('@/assets/icon-plus2x.png')" alt="">
          </div>
          <div class="item-info">Audience Coverage</div>
        </div>
      </div>
    </div>
    <div class="contact-box">
      <div class="contact-box-left">
        <div class="steps-box">
          <div class="step-item step-start">
            <img :src="require('@/assets/num-1.png')" class="step-item-img1" alt="">
            <img :src="require('@/assets/step1.png')" class="step-item-img2" alt="">
            <div class="step-info">
              <div class="step-title">
                Fill in the information
              </div>
              <div class="step-text">
                The first step is to fill in your basic information.
              </div>
            </div>
          </div>
          <div class="step-item step-end">
            <img :src="require('@/assets/num-2.png')" class="step-item-img1" alt="">
            <img :src="require('@/assets/step2.png')" class="step-item-img2" alt="">
            <div class="step-info">
              <div class="step-title">
                We will contact you within 24 hours.
              </div>
              <div class="step-text">
                Our customer specialist will contact you as soon as possible to discuss the details of cooperation.
              </div>
            </div>
          </div>
          <div class="step-item step-start">
            <img :src="require('@/assets/num-3.png')" class="step-item-img1" alt="">
            <img :src="setp3img" class="step-item-img2" alt="">
            <div class="step-info">
              <div class="step-title">
                {{step3Title}}
              </div>
              <div class="step-text">
                {{step3Text}}
              </div>
            </div>
          </div>
          <div class="step-item  step-end">
            <img :src="require('@/assets/num-4.png')" class="step-item-img1" alt="">
            <img :src="setp4img" class="step-item-img2" alt="">
            <div class="step-info">
              <div class="step-title">
                {{step4Title}}
              </div>
              <div class="step-text">
                {{step4Text}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :class="{'contact-box-right': true, 'contact-box-right-dark': contactType == 2} ">
        <div class="contact-form-box">
          <div class="form-title">
            {{formTitle}}
          </div>
          <div class="contact-form">
            <!--                    owner form start                        -->
            <el-form 
              ref="ruleFormRef1"
              v-if="contactType == 1" 
              :model="formOwner" 
              label-width="auto" 
              style="max-width: 600px">
              <!-- Title -->
              <el-form-item prop="user_type" :rules="[
                  {
                    required: true,
                    message: 'Please Select',
                    trigger: ['blur', 'change'],
                  },
                ]">
                <el-select v-model="formOwner.user_type" class="form-item-width" style="height: 48px;"  placeholder="Title">
                  <template #prefix>
                    <img class="form-icon" :src="require('@/assets/icon-form-title.png')" alt="">
                  </template>
                  <el-option label="Mr." value="Mr."></el-option>
                  <el-option label="Ms." value="Ms."></el-option>
                </el-select>
              </el-form-item>
              <!-- First Name -->
              <el-form-item prop="first_name" :rules="[
                  {
                    required: true,
                    message: 'Please Input',
                  },
                ]">
                <el-input v-model="formOwner.first_name" input-style="height:48px" placeholder="First Name">
                  <template #prefix>
                    <img class="form-icon" :src="require('@/assets/icon-form-firstname.png')" alt="">
                  </template>
                </el-input>
              </el-form-item>
              <!-- Last Name -->
              <el-form-item>
                <el-input v-model="formOwner.last_name" style="height: 48px;" placeholder="Last Name (Optional)">
                  <template #prefix>
                    <img class="form-icon" :src="require('@/assets/icon-form-lastname.png')" alt="">
                  </template>
                </el-input>
              </el-form-item>
              <!-- Contact Phone -->
              <el-form-item label="" prop="contact_num" :rules="[
                  {
                    required: true,
                    message: 'Please input contact number',
                  },
                  {
                    min:9,
                    max: 9,
                    message: 'Please input correct contact number',
                  },
                ]">
                <div class="contact-number-box">
                  <!-- <span class="country-code">+996</span> -->
                  <el-select
                    @change="countryCodeChange"
                    v-model="countryCode"
                    style="width: 100px;margin-right: 12px"
                  >
                    <el-option
                      v-for="item in options()"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                  <el-input 
                    @input="inputChange"
                    :class="{'contact-number-input': true, 'border-red': errorObj['contact_number']}" 
                    v-model="formOwner.contact_num" 
                    style="height: 48px" placeholder="Contact Number"
                  >
                    <!-- <template #prefix>
                      <img class="form-icon" :src="require('@/assets/icon-form-phone.png')" alt="">
                    </template> -->
                  </el-input>
                </div>
                
              </el-form-item>
              <!-- Email -->
              <el-form-item prop="user_email" :rules="[
                  {
                    type: 'email',
                    message: 'Please input correct email address',
                  },
                ]">
                <el-input 
                  @input="inputChange"
                  :class="{'border-red': errorObj['user_email']}"  
                  v-model="formOwner.user_email" style="height: 48px;" 
                  placeholder="Email (Optional)"
                >
                  <template #prefix>
                    <img class="form-icon" :src="require('@/assets/icon-form-email.png')" alt="">
                  </template>
                </el-input>
              </el-form-item>
              <!-- Build -->
              <el-form-item  prop="building_num" :rules="[
                  {
                    required: true,
                    message: 'Please Input',
                  },
                ]">
                <el-input v-model="formOwner.building_num" style="height: 48px;" placeholder="Number of Buildings">
                  <template #prefix>
                    <img class="form-icon" :src="require('@/assets/icon-form-build.png')" alt="">
                  </template>
                </el-input>
              </el-form-item>
              <!-- Area -->
              <el-form-item prop="total_building_area" :rules="[
                  {
                    required: true,
                    message: 'Please Input',
                  },
                ]">
                <el-input v-model="formOwner.total_building_area" style="height: 48px;" placeholder="Total Building Area (Estimate ㎡)">
                  <template #prefix>
                    <img class="form-icon" :src="require('@/assets/icon-form-area.png')" alt="">
                  </template>
                </el-input>
              </el-form-item>
              <!-- 提交 -->
              <el-form-item>
                <el-button class="form-submit-btn" type="primary" @click="onSubmit(ruleFormRef1)">
                  {{submitBtnText}}
                </el-button>
              </el-form-item>
            </el-form>
            <!--                    owner form end                       -->

            <!--                    client form start                        -->
            <el-form ref="ruleFormRef2" v-if="contactType == 2" :model="formClient" label-width="auto" style="max-width: 600px">
              <!-- Name -->
              <el-form-item prop="adv_type" :rules="[
                  {
                    required: true,
                    message: 'Please Select',
                  },
                ]">
                <el-select v-model="formClient.adv_type" class="form-item-width" style="height: 48px;"  placeholder="Name (Company/Individual)">
                  <template #prefix>
                    <img class="form-icon" :src="require('@/assets/icon-form-title.png')" alt="">
                  </template>
                  <el-option label="Company" value="Company"></el-option>
                  <el-option label="Individual" value="Individual"></el-option>
                </el-select>
              </el-form-item>
              <!-- Industry -->
              <el-form-item  prop="industry" :rules="[
                  {
                    required: true,
                    message: 'Please Input',
                  },
                ]">
                <el-input v-model="formClient.industry" input-style="height:48px" placeholder="Industry">
                  <template #prefix>
                    <img class="form-icon" :src="require('@/assets/icon-form-firstname.png')" alt="">
                  </template>
                </el-input>
              </el-form-item>
              <!-- Contact Person -->
              <el-form-item label="" prop="adv_name" :rules="[
                  {
                    required: true,
                    message: 'Please input Contact person',
                  },
                  {
                    type: 'string',
                    message: 'Please input Contact person',
                  },
                ]">
                <el-input v-model="formClient.adv_name" style="height: 48px;" placeholder="Contact person">
                  <template #prefix>
                    <img class="form-icon" :src="require('@/assets/icon-form-lastname.png')" alt="">
                  </template>
                </el-input>
              </el-form-item>
              <!-- Title -->
              <el-form-item prop="user_type" :rules="[
                  {
                    required: true,
                    message: 'Please Select',
                  },
                ]">
                <el-select v-model="formClient.user_type" class="form-item-width" style="height: 48px;"  placeholder="Title">
                  <template #prefix>
                    <img class="form-icon" :src="require('@/assets/icon-form-title.png')" alt="">
                  </template>
                  <el-option label="Mr." value="Mr."></el-option>
                  <el-option label="Ms." value="Ms."></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="contact_num" :rules="[
                  {
                    required: true,
                    message: 'Please input contact number',
                  },
                  {
                    min:9,
                    max: 9,
                    message: 'Please input correct contact number',
                  },
                ]">
                <div class="contact-number-box">
                  <el-select
                    v-model="countryCode"
                    placeholder="Select"
                    size="small"
                    style="width: 100px;margin-right: 12px"
                  >
                    <el-option
                      v-for="item in options()"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                  <!-- <span class="country-code">+996</span> -->
                  <el-input 
                  @input="inputChange"
                  :class="{'contact-number-input':true,'border-red': errorObj['contact_number']}" 
                   v-model="formClient.contact_num" 
                    style="height: 48px;" 
                    placeholder="Contact Number">
                    <!-- <template #prefix>
                      <img class="form-icon" :src="require('@/assets/icon-form-email.png')" alt="">
                    </template> -->
                  </el-input>
                </div>
                <!-- <el-input v-model="formClient.contact_number" style="height: 48px;" placeholder="Contact Number">
                  <template #prefix>
                    <img class="form-icon" :src="require('@/assets/icon-form-email.png')" alt="">
                  </template>
                </el-input> -->
              </el-form-item>
              <!-- Email -->
              <el-form-item label="" prop="user_email" :rules="[
                  {
                    type: 'email',
                    message: 'Please input correct email address',
                  },
                ]">
                <el-input 
                  @input="inputChange"
                  :class="{'border-red': errorObj['user_email']}"  
                   v-model="formClient.user_email"  
                   style="height: 48px;" 
                   placeholder="Email (Optional)"
                  >
                  <template #prefix>
                    <img class="form-icon" :src="require('@/assets/icon-form-build.png')" alt="">
                  </template>
                </el-input>
              </el-form-item>
              <!-- 提交 -->
              <el-form-item>
                <el-button class="form-submit-btn form-submit-btn-dark" type="primary" @click="onSubmit(ruleFormRef2)">
                  {{submitBtnText}}
                </el-button>
              </el-form-item>
            </el-form>
            <!--                    client form end                       -->
          </div>

        </div>
        
      </div>
    </div>
    <!-- 提交后的弹窗 -->
    <el-dialog v-model="dialogVisible" modal-class="my-dialog-box"  center :close="closeDialog">
      <div class="submit-message-box">
        <!-- <div> -->

          <div class="my-dialog-icon">
            <img :src="require('@/assets/Vector.png')" width="48px" height="48px" alt="">
          </div>
          <div class="my-dialog-title">
            Fill in successfully
          </div>
          <div class="my-dialog-message">
            Good news! It is estimated that every 1,000 square meters can generate a minimum annual guarantee of 1,000 SAR for your property.
          </div>
          <div class="my-dialog-message">
            Our business representative will contact you ASAP and we are looking forward to serving you further.
          </div>

        <!-- </div> -->

      </div>
      <!-- <template #footer>
        <div class="dialog-footer">
          <el-button @click="centerDialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="centerDialogVisible = false">
            Confirm
          </el-button>
        </div>
      </template> -->
    </el-dialog>
  </div>
</template>

<script setup>
import {countryCodeListObj} from './countryCodeList'
import axios from 'axios'
import { reactive, watch, ref } from 'vue';
import {useRouter, useRoute} from 'vue-router';
import { ElMessage } from 'element-plus'
// import type { ComponentSize, FormInstance, FormRules } from 'element-plus'

const router = useRouter();
const currentRoute = useRoute();
let contactType = currentRoute.query.type || 1;
console.log(router, currentRoute.query, contactType);
const dialogVisible = ref(false);
const ruleFormRef1 = ref();
const ruleFormRef2 = ref();
const nowDate = new Date();
const year = nowDate.getFullYear();
const month = nowDate.getMonth() + 1;
const day = nowDate.getDate();
const nowDateStr = ref(year +'/'+ month +'/'+day)

const countryCode = ref('+966');
  // console.log(countryCodeListObj)
const options = () => {
  // console.log(countryCodeListObj)
  let arr = []
  for (let key in countryCodeListObj) {
    let tmpObj = {label: countryCodeListObj[key], value: countryCodeListObj[key]}
    arr.push(tmpObj)
  }
  // console.log('options', arr)
  return arr
}
console.log(options(), 'opt')

const countryCodeChange = (value) => {
  console.log(value)
  countryCode.value = value
}

let formTitle = ref('');
if (contactType == 1) {
  formTitle.value = 'Estimated Property Owner Income （Yearly）'
} else {
  formTitle.value = 'Advertiser cooperation process'
}

// const contact_num

const formOwner = reactive({
  user_type: '',
  first_name: '',
  last_name: '',
  user_email: '',
  contact_num: '',
  building_num: '',
  total_building_area: '',
})
const formClient = reactive({
  adv_type: '',
  user_type: '',
  adv_name: '',
  industry: '',
  user_email: '',
  contact_num: '',
})


const errorObj = ref({});

const inputChange = (value) => {
  console.log('inputChange', value)
  errorObj.value = {}
}

const onSubmit = async(formEl) => {
  console.log('submit!', formOwner)
  // dialogVisible.value = true;
  console.log(dialogVisible);
  let url = '';
  // let params
  if (contactType == 1) {
    url = 'elevator_owner_info'
    // formOwner.fields();
    console.log('formmmm',formOwner.building_num)
    formOwner.contact_number = countryCode.value + formOwner.contact_num;
    await formEl.validate((valid, fields) => {
      if (valid) {
        console.log('submit!')
        axios.post(`/api/${url}/`, formOwner).then (res=> {
          console.log(res);
          if (res.data.status == 'error') {
            if (res.data.errors) {
              for (let key in res.data.errors) {
                console.log('error::'+key)
                ElMessage.error({
                  message: res.data.errors[key][0],
                  duration: 3000
                })
                errorObj.value[key] = true;
                // console.log('errorObj',errorObj)
              }
            }
          } else {
            dialogVisible.value = true;
          }
        }).catch(err => {
          console.log(err);
          ElMessage.error({
            message: 'Oops, this is a error',
            duration: 3000
          })
        })
      } else {
        console.log('error submit!', fields)
      }
    })
  } else {
    url = 'adv_info'
    console.log('formEl',formClient, formEl)
    formClient.contact_number = countryCode.value + formClient.contact_num;
    await formEl.validate((valid, fields) => {
      if (valid) {
        console.log('submit!')
        axios.post(`/api/${url}/`, formClient).then(res=> {
          console.log(res);
          if (res.data.status == 'error') {
            if (res.data.errors) {
              for (let key in res.data.errors) {
                console.log('error::'+key)
                ElMessage.error({
                  message: res.data.errors[key][0],
                  duration: 3000
                })
                errorObj.value[key] = true;
              }
            }
          } else {
            dialogVisible.value = true;
          }
        }).catch(err => {
          console.log(err);
        })
      } else {
        console.log('error submit!', fields)
      }
    })
  }
}
const closeDialog = () => {
  dialogVisible.value = false
}
const submitBtnText = ref('Calculate the expected income');
const setp3img = ref(require('@/assets/step3.png')) 
const step3Title = ref('Grant us authorization.')
const step3Text = ref('We don’t charge you any fees. Once authorized, we’ll find top advertisers to collaborate with for your building.')
const setp4img = ref(require('@/assets/step4.png')) 
const step4Title = ref('Just collect your monthly payment.')
const step4Text = ref('Starting from the first day the advertising is posted, you’ll receive a fixed payment every month.')
watch(()=>currentRoute.query, (newVal, oldVal) => {
  console.log('URL', newVal, oldVal);
  contactType = newVal.type;
  if (contactType == 1) {
    formTitle.value = 'Estimated Property Owner Income （Yearly）'
    submitBtnText.value = 'Calculate the expected income'
    setp3img.value = require('@/assets/step3.png')
    step3Title.value = 'Grant us authorization.'
    step3Text.value = 'We don’t charge you any fees. Once authorized, we’ll find top advertisers to collaborate with for your building.'
    setp4img.value = require('@/assets/step4.png')
    step4Title.value = 'Just collect your monthly payment.'
    step4Text.value = 'Starting from the first day the advertising is posted, you’ll receive a fixed payment every month.'
  } else {
    formTitle.value = 'Advertiser cooperation process'
    submitBtnText.value = 'Request for Quotation'
    setp3img.value = require('@/assets/step33.png')
    step3Title.value = 'The ads are now being gradually deployed.'
    step3Text.value = 'Your brand or product is gradually coming into view for every audience.'
    setp4img.value = require('@/assets/step44.png')
    step4Title.value = 'Confirm the details and publish'
    step4Text.value = 'The last step is to confirm all the details and then start running your advertisement in the elevator.'
  }
})
</script>

<style>
.contact-us-page {
  
}
.updateing-box {
  height: 400px;
  background-image: url('../../assets/contactbgimg.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  padding: 120px;
}
.updateing-box-bg-client {
  background-image: url('../../assets/contactclientbgimg.png');
}
.top-text {
  font-family: DIN;
  font-size: 80px;
  font-weight: 700;
  line-height: 96px;
  text-align: center;
  color:#fff;
}
.time-box {
  display: flex;
  justify-content: center;
  padding: 40px 0;
}
.time-box img{
  width: 24px;
}
.time {
  margin-left: 12px;
  font-family: Inter;
  font-size: 28px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color:#fff;
}
.bottom-data {
  display: flex;
  justify-content: space-around;
}
.item-num {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: DIN;
  font-size: 70px;
  font-weight: 700;
  line-height: 86px;
  text-align: left;
  color: #fff;
}
.item-num img {
  width: 30px;
  height: 30px;
}
.item-info {
  font-family: Alibaba PuHuiTi 2.0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #fff;
}
.contact-box {
  /* width: 100%; */
  height: 800px;
  display: flex;
  justify-content: center;
  
}
.contact-box-left {
  display: flex;
  align-items: center;
  margin-right:60px;
}
.steps-box {
  width: 620px;
}
.step-item {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 45px;
}

.contact-us-page  .step-info {
  width: 300px;
  margin-left: 10px;
}
.step-item-img1 {
  width: 44px;
}
.step-item-img2 {
  width: 56px;
}
.step-end {
  justify-content: end;
  margin-right: 20px
}
.step-title, .step-text {
  font-family: Alibaba PuHuiTi 2.0;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #000;
}
.step-title {
  font-weight: 500;
}
.contact-box-right {
  padding: 50px;
  margin-top: 70px;
  background: #fff;
  /* border-radius: 8px; */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 400px;
  /* height: 730px; */
  box-shadow: 0px 4px 8px 4px #116AE21A;
}

.contact-form .contact-number-box {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
}
.contact-number-box .el-select__wrapper {
  height: 48px;
}
.contact-form .contact-number-input {
  width: 100%;
}
.contact-form .country-code {
  display: inline-block;
  height: inherit;
  width: 100px;
  line-height: 44px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  margin-right: 12px;
}
.form-title {
  font-family: Alibaba PuHuiTi 2.0;
  font-size: 32px;
  font-weight: 700;
  line-height: 44.8px;
  text-align: center;
  color: #000;
  margin-bottom: 36px;
}
.border-red {
  border: 1px solid #f56c6c;
  border-radius: 4px;
}
.form-icon {
  width: 20px;
  height: 20px;
}
.form-item-width div{
  height: 48px;
  display: flex;
  align-items: center;
}
.form-submit-btn {
  width: 100%;
  height: 48px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 48px;
  text-align: center;
  color: #fff;
}
.form-submit-btn .el-form-item__content {
  height: 48px;
}
.form-submit-btn-dark {
  background: rgba(16, 106, 226, 1);

}
.my-dialog-box .el-dialog{
  width: 700px
}
.submit-message-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
}
.my-dialog-icon {
  margin-bottom: 12px;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgba(103, 235, 178, 0.1);
}
.my-dialog-title {
  font-family: Alibaba PuHuiTi 2.0;
  font-size: 32px;
  font-weight: 700;
  line-height: 44.8px;
  text-align: center;
  color:#000;
}
.my-dialog-message {
  font-family: Alibaba PuHuiTi 2.0;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  color:#000;
}
@media screen and (max-width: 830px) {
  .updateing-box {
    /* height: 21vh; */
    height: 220px;
    padding: 50px 0;
    background-size: auto 100%;
  }
  .top-text {
    font-size: 28px;;
    line-height: 28px;
  }
  .time {
    font-size: 14px;
    line-height: 14px;
  }
  .time-box img {
    width: 14px;
    height: 14px;
  }
  .bottom-data, .item-num {
    font-size: 32px;
    line-height: 32px;;
  }
  .item-num img {
    width: 14px;
    height: 14px;
  }
  .contact-box {
    display: block;
    height: auto;
  }
  .contact-box-left {
    padding: 40px 20px 40px 20px;
    margin: 0;
  }
  .step-end {
    margin: 0;
  }
  .step-info {
    width: 200px;
    margin-left: 10px;
  }
  .step-item-img1 {
    width: 12px;
    margin-left: 4px;
  }
  .step-item-img2 {
    width: 30px;
  }
  .step-title {

  }
  .step-text {
    font-weight: 100;
  }
  .contact-box-right {
    margin: 0;
    padding: 40px 0;
    /* padding: 40px 20px 0 20px; */
    background: rgba(8, 165, 243, 1);
    border-radius: 0;
    width: 100%;
  }
  .contact-box-right-dark {
    background: rgba(16, 106, 226, 1);
  }
  .contact-form-box {
    background: #fff;
    border-radius: 8px;
    margin: 0 20px;
  }
  .form-title {
    padding: 20px 20px 0;
    margin-bottom: 0;
    font-family: Alibaba PuHuiTi 2.0;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .contact-form {
    padding: 20px;
  }
  .contact-form .contact-number-box {
    width: 100%;
    display: flex;
  }
  .contact-form .contact-number-input {
    width: 100%;
  }
  .contact-form .country-code {
    display: inline-block;
    height: inherit;
    width: 100px;
    line-height: 44px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    margin-right: 12px;
  }

  .my-dialog-box .el-dialog{
    width: 80%;
  }
  .submit-message-box {
    padding: 0;
  }
  .my-dialog-title {
    font-size: 24px;
  }
  .my-dialog-message {
    font-size: 14px;
  }
}

</style>