<template>
  <div class="first-part">
    <div class="title-box">
      <div class="top-title">
        SwidaVision Media
      </div>
      <div class="top-message">
        New advertising channel: indoor ads get more attention
      </div>
      <div class="buttons-group">
        <el-button 
          @click="goTo('contact', {type:1})"
          type="primary"
          class="button-item"
         >Property Owner</el-button>
        <el-button 
         @click="goTo('contact', {type:2})"
        type="primary" 
        class="button-item button-item-dark"
        >Advertising Client</el-button>
      </div>
    </div>
  </div>
</template>

<script setup>
// import { ref } from 'vue'
import {useRouter, useRoute} from 'vue-router'
const router = useRouter();
const route = useRoute();


const goTo = (path, params) => {
  if (path) {
    router.push({name: path, query:params});
  } else {
    router.push('/')
  }
  console.log(path, router,route);
}

</script>

<style>
.first-part {
  height: 640px;
  background-image: url('../../assets/firstPart.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.first-part .title-box {
  padding: 120px;
}

.top-title {
  font-family: DIN;
  font-size: 120px;
  font-weight: 700;
  line-height: 146.52px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color:#fff;
}
.top-message {
  font-family: Inter;
  font-size: 40px;
  font-weight: 600;
  line-height: 48.41px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color:#fff;
}
.buttons-group {
  margin-top: 40px;
}
.button-item {
  width: 220px;
  height: 56px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  border: none;
  background: rgba(8, 164, 243, 1);

}
.button-item:hover {
  background: rgb(94, 190, 238);
}
.button-item-dark {
  background: rgba(16, 106, 226, 1);
}
.button-item-dark:hover{
  background: rgb(57, 132, 231);
}
@media screen and (max-width: 830px) {
  .first-part {
    height: 220px;
    padding: 50px 0 ;
    background-size: auto 100%;
  }
  .first-part .title-box {
    padding: 20px;
  }
  .top-title {
    font-size: 38px;
    line-height: 38px;
  }
  .top-message {
    margin-top: 20px;
    font-size: 20px;
    line-height: 30px;
  }
  .button-item {
    width: 156px; 
    height: 44px;
    font-size: 14px;
    line-height: 24px;
  }
}
</style>