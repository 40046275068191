<template>
  <div class="second-part" id="ads_part">
    <transition name="fade-transform">
    <div v-show="showtype == 1" class="advantage-part">
      <div class="bg-color">
        <div class="bg-blue"></div>
        <div class="bg-white"></div>
      </div>
      <div class="up-box">
        <div class="up-title">Why Choose SwidaVision？</div>
        <div class="up-buttons">
            <el-button 
              style="color: #02A4E5;"
              class="button-item button-owner"
              @click="changeType(1)"
              >Property Owner</el-button>
            <el-button 
              type="primary" 
              style="background: rgba(8, 165, 243, 1);"
              class="button-item button-client"
              @click="changeType(2)"
              >Advertising Client</el-button>
        </div>
        <div class="up-content">
          <div class="up-content-item">
            <img :src="require('@/assets/advantage1.png')" width="100px" alt="">
            <div class="item-title">Users take the initiative to watch</div>
            <div class="item-text">
              Among all forms of advertising, the audience of elevator advertisements will take the initiative to watch.
            </div>
          </div>
          <div class="up-content-item">
            <img :src="require('@/assets/advantage2.png')" width="100px" alt="">
            <div class="item-title">On the way everyone must go</div>
            <div class="item-text">
              Indoor pathways that people pass through daily, allow for multiple long exposures everyday, creating a more lasting impression.
            </div>
          </div>
          <div class="up-content-item">
            <img :src="require('@/assets/advantage3.png')" width="100px" alt="">
            <div class="item-title">High-frequency exposure</div>
            <div class="item-text">
              Each person uses the elevator multiple times a day, twice each time they go out, resulting in high-frequency exposure.
            </div>
          </div>
          <div class="up-content-item">
            <img :src="require('@/assets/advantage4.png')" width="100px" alt="">
            <div class="item-title">No outside interference</div>
            <div class="item-text">
              Indoor ads are simple and focused, capturing attention in places like elevators and hallways where mobile signals don't reach.
            </div>
          </div>
        </div>
      </div>
    </div>

  </transition>
  <transition name="fade-transform">
    <div v-show="showtype == 2" class="rights-part">
      <div class="bg-color">
        <div class="bg-blue"></div>
        <div class="bg-white"></div>
      </div>
      <div class="up-box">
        <div class="up-title">What’s in it for Property Owners？</div>
        <div class="up-buttons">
            <el-button 
              style="background: #106AE2;"
              class="button-item button-client" 
              @click="changeType(1)">Property Owner</el-button>
            <el-button 
              style="color: #106AE2;"
              class="button-item button-owner" 
              type="primary" @click="changeType(2)">Advertising Client</el-button>
        </div>
        <div class="up-content">
          <div class="up-content-item">
            <img :src="require('@/assets/rights1.png')" width="100px" alt="">
            <div class="item-title">Users take the initiative to watch</div>
            <div class="item-text">
              Among all forms of advertising, the audience of elevator advertisements will take the initiative to watch.
            </div>
          </div>
          <div class="up-content-item">
            <img :src="require('@/assets/rights2.png')" width="100px" alt="">
            <div class="item-title">On the way everyone must go</div>
            <div class="item-text">
              Indoor pathways that people pass through daily, allow for multiple long exposures everyday, creating a more lasting impression.
            </div>
          </div>
          <div class="up-content-item">
            <img :src="require('@/assets/rights3.png')" width="100px" alt="">
            <div class="item-title">High-frequency exposure</div>
            <div class="item-text">
              Each person uses the elevator multiple times a day, twice each time they go out, resulting in high-frequency exposure.
            </div>
          </div>
          <div class="up-content-item">
            <img :src="require('@/assets/rights4.png')" width="100px" alt="">
            <div class="item-title">No outside interference</div>
            <div class="item-text">
              Indoor ads are simple and focused, capturing attention in places like elevators and hallways where mobile signals don't reach.
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  </div>
</template>

<script setup>
import { ref } from 'vue'
// import {useRouter, useRoute} from 'vue-router'
// const router = useRouter();
// const route = useRoute();


// const switchMenu = () => {
//   showMenu.value = !showMenu.value
// }

// const goTo = (path, params) => {
//   if (path) {
//     router.push({name: path, query:params});
//     // switchMenu()
//   } else {
//     router.push('/')
//   }
//   console.log(path, router,route);
// }
const showtype = ref(1)
const changeType = (type) => {
  console.log(type)
  showtype.value = type
}
</script>

<style>
.second-part {
  position: relative;
  height: 790px;
  width: 100%;
}
.advantage-part {
  height: 790px;
  position: relative;
  width: 100%;
  position: absolute;
  top:0;
  left: 0;
}
.rights-part {
  height: 790px;
  width: 100%;
  position: absolute;
  top:0;
  left: 0;
}
.bg-color {
  
}
.advantage-part .bg-blue {
  height: 560px;
  /* background-color: rgb(110, 179, 232, 0.6); */
  background-color: rgba(8, 165, 243, 1);
  background-image: url('../../assets/bgimgdot2x.png');
  background-size: 100% auto;
}
.rights-part .bg-blue {
  height: 560px;
  /* background-color: rgba(14, 48, 241, 0.6); */
  background-color: rgba(16, 106, 226, 1);
  background-image: url('../../assets/bgimgdot2x.png');
  background-size: 100% auto;

}
.bg-white {
  height: 230px;
  background-color: transparent;
}
.up-box {
  background: transparent;
  position: absolute;
  left:0;
  top: 0;
  width: 100%;
  height: 790px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  padding-top: 100px;
}
.up-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
  height: 48px;
  color: #fff;
}
.up-buttons {
  margin: 40px 0;
}
.up-buttons .button-item {
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  background: #fff;
  border: 1px solid #fff;
}
.up-buttons .button-owner {
  color: #02A4E5;
}
.up-buttons .button-client {
  color: #fff;
}
.up-content {
  display: flex;
  justify-content: space-around;
}
.up-content-item {
  padding: 16px;
  width: 260px;
  height: 360px;
  background-color: #fff;
  background-image: url('../../assets/bgimg.png');
  background-position: -1px -48px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 4px 8px 4px #116AE21A;
  border-radius: 8px;
}
.up-content-item .item-title {
  color: #333;
  font-size: 24px;
  font-weight: 500;
}
.up-content-item .item-text {
  color: #333;
  font-size: 16px;
  font-weight: 400;
}
@media screen and (max-width: 830px) {

.second-part {
  position: relative;
  height: 900px;
  width: 100%;
}
  .advantage-part, .rights-part{
    height: inherit;
  }

  .advantage-part .bg-color .bg-blue {
    height: 400px;
  }
  .advantage-part .bg-color .bg-white {
    height: 500px;
  }

  .rights-part .bg-color .bg-blue {
    height: 400px;
  }
  .rights-part .bg-color .bg-white {
    height: 500px;
  }
  .up-box {
    padding: 0;
    height: inherit;
  }
  .up-title {
    padding: 0 20px;
    margin-top: 40px;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    height: 80px;
  }
  .up-buttons .button-item {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
  }
  .up-content {
    flex-wrap: wrap;
    padding: 0 1vw;
  }
  .up-content .up-content-item {
    width: 37vw;
    /* height: 290px */
    height: inherit;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
  .up-content .up-content-item .item-title {
    font-size: 16px;
    line-height: 24px;
  }
  .up-content .up-content-item .item-text {
    font-size: 12px;
    line-height: 20px;
  }
}

/* .fade-transform-leave-active, .fade-transform-enter-active {
  transition: all .5s;
}
.fade-transform-enter-to {
  opacity: 0;
  transform: translateX(-100px);
}
.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(100px);
} */


</style>