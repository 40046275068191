<template>
    <div class="header-components">
      <div class="logo" @click="goTo()">
        <img :src="require('@/assets/logo4x.png')" alt="">
      </div>
      <div class="right-box">
        <div class="menu-box">
          <div class="menu-item" @click="anchorTo('about_us_part')">About Us</div>
          <div class="menu-item" @click="anchorTo('ads_part')">Indoor Ads</div>
          <div class="menu-item" @click="anchorTo('cases_part')">Cases</div>
          <div class="menu-item" @click="anchorTo('brands_part')">Brands
          </div>
        </div>
        <div class="mobile-menu-box" @click="switchMenu">
          <div class="mobile-menu-icon">
            <img :src="require('@/assets/mobile-icon-menu.png')" alt="">
          </div>
        </div>
        <div :class="{'mobile-menu-main': true, 'mobile-menu-main-show': showMenu}">
          <div class="mobile-menu-item-box">
            <div class="mobile-menu-item" @click="anchorTo('about_us_part')">
              <div>About Us</div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="M340.864 149.312a30.592 30.592 0 0 0 0 42.752L652.736 512 340.864 831.872a30.592 30.592 0 0 0 0 42.752 29.12 29.12 0 0 0 41.728 0L714.24 534.336a32 32 0 0 0 0-44.672L382.592 149.376a29.12 29.12 0 0 0-41.728 0z"></path></svg>
            </div>
            <div class="mobile-menu-item" @click="anchorTo('ads_part')">
              <div>Indoor Ads</div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="M340.864 149.312a30.592 30.592 0 0 0 0 42.752L652.736 512 340.864 831.872a30.592 30.592 0 0 0 0 42.752 29.12 29.12 0 0 0 41.728 0L714.24 534.336a32 32 0 0 0 0-44.672L382.592 149.376a29.12 29.12 0 0 0-41.728 0z"></path></svg>
            </div>
            <div class="mobile-menu-item" @click="anchorTo('cases_part')">
              <div>Cases</div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="M340.864 149.312a30.592 30.592 0 0 0 0 42.752L652.736 512 340.864 831.872a30.592 30.592 0 0 0 0 42.752 29.12 29.12 0 0 0 41.728 0L714.24 534.336a32 32 0 0 0 0-44.672L382.592 149.376a29.12 29.12 0 0 0-41.728 0z"></path></svg>
            </div>
            <div class="mobile-menu-item" @click="anchorTo('brands_part')">
              <div>Brands</div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="M340.864 149.312a30.592 30.592 0 0 0 0 42.752L652.736 512 340.864 831.872a30.592 30.592 0 0 0 0 42.752 29.12 29.12 0 0 0 41.728 0L714.24 534.336a32 32 0 0 0 0-44.672L382.592 149.376a29.12 29.12 0 0 0-41.728 0z"></path></svg>
            </div>
            <div class="mobile-menu-item" @click="goTo('contact', {type:1})">
              <div>Property Owner</div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="M340.864 149.312a30.592 30.592 0 0 0 0 42.752L652.736 512 340.864 831.872a30.592 30.592 0 0 0 0 42.752 29.12 29.12 0 0 0 41.728 0L714.24 534.336a32 32 0 0 0 0-44.672L382.592 149.376a29.12 29.12 0 0 0-41.728 0z"></path></svg>
            </div>
            <div class="mobile-menu-item" @click="goTo('contact', {type:2})">
              <div>Advertising Client</div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="M340.864 149.312a30.592 30.592 0 0 0 0 42.752L652.736 512 340.864 831.872a30.592 30.592 0 0 0 0 42.752 29.12 29.12 0 0 0 41.728 0L714.24 534.336a32 32 0 0 0 0-44.672L382.592 149.376a29.12 29.12 0 0 0-41.728 0z"></path></svg>
            </div>
          </div>
        </div>
        <div class="active-bottons">
          <el-button style="width: 150px;height: 40px;" @click="goTo('contact', {type:1})">Property Owner</el-button>
          <el-button style="width: 150px;height: 40px;" @click="goTo('contact', {type:2})" type="primary">Advertising Client</el-button>
        </div>
        <div class="lang">
          <el-dropdown>
            <span class="lang-box">
              <img :src="require('@/assets/icon-header-lang.png')" width="17px" alt="">
              <span class="lang-str"> {{langStr}} </span>
              <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path fill="currentColor" d="M831.872 340.864 512 652.672 192.128 340.864a30.592 30.592 0 0 0-42.752 0 29.12 29.12 0 0 0 0 41.6L489.664 714.24a32 32 0 0 0 44.672 0l340.288-331.712a29.12 29.12 0 0 0 0-41.728 30.592 30.592 0 0 0-42.752 0z"></path></svg>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="selectLang('EN')">EN</el-dropdown-item>
                <el-dropdown-item @click="selectLang('AR')">AR</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          </div> 
      </div>
    </div>
</template>
<script setup>
import { ref } from 'vue'
import {useRouter, useRoute} from 'vue-router'
const router = useRouter();
const route = useRoute();

const anchorTo = (anchorName) => {
  console.log(route,anchorName);
  console.log(route.fullPath)
  let fullPath = route.fullPath
  if (fullPath === '/' || fullPath === '/home') {
    window.scrollTo({
      top: document.getElementById(anchorName)?.offsetTop,
      behavior: 'smooth'
    })
  } else {
    router.push('/');
    setTimeout(()=>{
      window.scrollTo({
        top: document.getElementById(anchorName)?.offsetTop,
        behavior: 'smooth'
      })
    }, 700)
  }
  switchMenu()
}
const goTo = (path, params) => {
  if (path) {
    router.push({name: path, query:params});
    switchMenu()
  } else {
    router.push('/')
  }
  console.log(path, router,route);
}

const langStr = ref('EN');
const selectLang = (lang) => {
  console.log(lang);
  langStr.value = lang;
}

const showMenu = ref(false)
const switchMenu = () => {
  showMenu.value = !showMenu.value
}

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .header-components {
    width: 100%;
    /* height: 100px; */
    background: #FFF; 
    display: flex;
    padding: 20px 0;
    justify-content: space-between;
  }
  .logo {
    width: 15vw;
    /* height: 5vh; */
    /* background: black; */
    cursor: pointer;
    margin-left: 20px;
  }
  .logo img {
    height: 40px;
  }
  .right-box {
    display: flex;
    justify-content: flex-end;
    width: calc(100% - 15vw);
    margin-right: 20px;
  }
  .menu-box {
    display: flex;
    /* justify-content: space-around; */
    /* width: 50%; */
  }
  .menu-item {
    cursor: pointer;
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: 400;
    line-height: 48px;
    text-align: left;
    height:48px;
    margin-right: 40px;
  }
  .menu-item:hover {
    color: rgb(85, 206, 243);
  }
  .mobile-menu-box {
    display: none;
  }
  .active-bottons {
    display: flex;
    align-items: center;
  }
  .active-bottons button:nth-child(2) {
    margin-left: 16px;
  }
  .lang {
    margin-left: 16px;
    width: 100px;
    font-size: 20px;
    display: flex;
    align-items: center;
  }
  .lang-box {
    font-size: 20px;
    display: flex;
    align-items: center;
  }
  .lang-str {
    cursor: pointer;
    margin: 0 8px;
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

  }
  .lang svg {
    width: 18px;
    height: 18px;
  }
  .mobile-menu-icon, .mobile-menu-icon img{
    width: 40px;
    height: 40px;
  }
  .mobile-menu-main {
    display: none;
  }
@media screen and (min-width: 1200px) {
  .header-components {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }
}
@media screen and (min-width: 830px) and (max-width: 1200px) {
  .menu-box {
    justify-content: flex-end;
  }
  .menu-item {
    margin-right:1vh;
  }
}
@media screen and (max-width: 830px) {
  .menu-box {
    display: none;
  }
  .header-components {
    position: relative;
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.06);
  }
  .header-components .logo {
    width: 150px;
    height: 30px;
  }
  .header-components .logo img {
    width: 150px;
    height: 30px;
  }
  .header-components .right-box {
    width: inherit;
  }
  .mobile-menu-box {
    display: block;
  }
  .mobile-menu-main {
    z-index: 99;
    display: none;
    position: absolute;
    width: 100vw;
    height: 80vh;
    background:#fff;
    top: 10vh;
    left: 0;
  }
  .mobile-menu-main-show {
    display: block;
  }
  .mobile-menu-item-box {
    padding: 0 3vh;
  }
  .mobile-menu-item {
    height: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #000;
  }
  .mobile-menu-item:hover {
    color: #4791FF;
  }
  .mobile-menu-item svg {
    width: 20px;
    height: 20px;
  }
  .active-bottons {
    display: none;
  }
}
    
</style>
